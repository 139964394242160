var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _vm._v(" "),
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
        attrs: { id: "breadcrumb-container" },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "el-popover",
            { attrs: { placement: "bottom", width: "280", trigger: "click" } },
            [
              _c("div", [
                _c(
                  "div",
                  {
                    staticStyle: {
                      height: "175px",
                      background: "#2e8cff47",
                      "text-align": "center",
                      "padding-top": "40px",
                    },
                  },
                  [
                    _c("img", {
                      staticStyle: {
                        width: "90px",
                        height: "90px",
                        "border-radius": "50%",
                      },
                      attrs: { src: _vm.Logo },
                    }),
                    _vm._v(" "),
                    _c("div", { staticStyle: { "text-align": "center" } }, [
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function ($event) {
                              return _vm.changeUpdate()
                            },
                          },
                        },
                        [_vm._v("版本:" + _vm._s(_vm.updataarr[0].edition))]
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      padding: "15px 0",
                      display: "flex",
                      "text-align": "center",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticStyle: { width: "49%", padding: "0 10px" } },
                      [_vm._v(_vm._s(_vm.userUnit.name))]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticStyle: { width: "49%", padding: "0 10px" } },
                      [_vm._v(_vm._s(_vm.userName))]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "el-button-group",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.changePW()
                              },
                            },
                          },
                          [_vm._v("修改密码")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.changeUserInfo()
                              },
                            },
                          },
                          [_vm._v("修改信息")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticStyle: { float: "right" },
                        attrs: { size: "small" },
                        on: { click: _vm.logout },
                      },
                      [_vm._v("注销")]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: { "margin-right": "20px", cursor: "pointer" },
                  attrs: { slot: "reference" },
                  slot: "reference",
                },
                [
                  _c("img", {
                    staticStyle: {
                      width: "40px",
                      height: "40px",
                      "border-radius": "50%",
                      float: "left",
                      margin: "5px",
                    },
                    attrs: { src: _vm.Logo },
                  }),
                  _vm._v(_vm._s(_vm.userName) + "\n      "),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-badge",
        {
          staticStyle: {
            float: "right",
            "margin-top": "10px",
            "margin-right": "20px",
          },
          attrs: { value: _vm.reportCount, dispaly: "none" },
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  return _vm.Detail(_vm.reportCount, 1)
                },
              },
            },
            [_vm._v("未接单")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-badge",
        {
          staticStyle: {
            float: "right",
            "margin-top": "10px",
            "margin-right": "20px",
          },
          attrs: { value: _vm.repairCount, dispaly: "none" },
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  return _vm.Detail(_vm.reportCount, 2)
                },
              },
            },
            [_vm._v("处理中")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-badge",
        {
          staticStyle: {
            float: "right",
            "margin-top": "10px",
            "margin-right": "20px",
          },
          attrs: { value: _vm.reviewCount, dispaly: "none" },
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  return _vm.Detail(_vm.reportCount, 3)
                },
              },
            },
            [_vm._v("待验收")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-badge",
        {
          staticStyle: {
            float: "right",
            "margin-top": "10px",
            "margin-right": "20px",
          },
          attrs: { value: _vm.reportDamageReviewCount, dispaly: "none" },
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  return _vm.Detail2()
                },
              },
            },
            [_vm._v("报损待审核")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改信息",
            visible: _vm.changeUserFormVisible,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            width: "450px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.changeUserFormVisible = $event
            },
            close: _vm.changeUserFormClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "UserForm",
              attrs: {
                model: _vm.UserForm,
                rules: _vm.UserFormRules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "登录账号", prop: "userName" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: "",
                      placeholder: "登录账号",
                      userze: "small",
                    },
                    model: {
                      value: _vm.UserForm.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.UserForm, "userName", $$v)
                      },
                      expression: "UserForm.userName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "用户名称", prop: "trueName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "用户名称", userze: "small" },
                    model: {
                      value: _vm.UserForm.trueName,
                      callback: function ($$v) {
                        _vm.$set(_vm.UserForm, "trueName", $$v)
                      },
                      expression: "UserForm.trueName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "contactNumber", label: "联系电话" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "联系电话", size: "small" },
                    model: {
                      value: _vm.UserForm.contactNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.UserForm, "contactNumber", $$v)
                      },
                      expression: "UserForm.contactNumber",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "" },
                  on: {
                    click: function ($event) {
                      _vm.changeUserFormVisible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submit()
                    },
                  },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改密码",
            visible: _vm.changePassWordVisibale,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            width: "450px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.changePassWordVisibale = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "changePassWord",
              attrs: {
                model: _vm.changePassWord,
                rules: _vm.changePassWordRules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "oldPassword", label: "旧密码" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "旧密码", size: "small" },
                    model: {
                      value: _vm.changePassWord.oldPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.changePassWord, "oldPassword", $$v)
                      },
                      expression: "changePassWord.oldPassword",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "newPassword", label: "新密码" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "password",
                      placeholder: "新密码",
                      size: "small",
                    },
                    model: {
                      value: _vm.changePassWord.newPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.changePassWord, "newPassword", $$v)
                      },
                      expression: "changePassWord.newPassword",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "confirmPassword", label: "确认密码" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "password",
                      placeholder: "确认密码",
                      size: "small",
                    },
                    model: {
                      value: _vm.changePassWord.confirmPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.changePassWord, "confirmPassword", $$v)
                      },
                      expression: "changePassWord.confirmPassword",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "" },
                  on: {
                    click: function ($event) {
                      _vm.changePassWordVisibale = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitChangePassWord()
                    },
                  },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "abow_dialog",
          attrs: {
            title: "版本更新内容",
            visible: _vm.changeUpdateVisibale,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.changeUpdateVisibale = $event
            },
          },
        },
        [
          _c("div", {
            staticStyle: {
              "white-space": "pre-wrap",
              height: "250px",
              "overflow-y": "auto",
            },
            domProps: { innerHTML: _vm._s(_vm.updata) },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }