<template>
  <div :class="{'has-logo':showLogo}">
    <logo v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu :default-active="getActiveMenu" class="el-menu-vertical-demo menu" router :collapse="isCollapse" background-color="#304156" text-color="#fff" active-text-color="#409eff">
        <el-menu-item v-show="roles.includes('PlatformOverview')" index="/">
          <i class="fa fa-map" />
          <span slot="title"><a :href="url" target="_blank">运维平台概览展示</a></span>
        </el-menu-item>
        <!-- <el-menu-item v-show="roles.includes('PlatformOverview')" index="/">
          <i class="fa fa-map" />
          <span slot="title"><a :href="url1" target="_blank">运维平台视频协助</a></span>
        </el-menu-item>
        <el-menu-item v-show="roles.includes('PlatformOverview')" index="/">
          <i class="fa fa-map" />
          <span slot="title"><a :href="url2" target="_blank">运维平台数据分析</a></span>
        </el-menu-item> -->
        <el-submenu v-for="(item,index) in routers" v-show="!item.meta.hidden && isShowParentMenu(item.children)" :key="'title'+ index" :index="item.meta.fullPath" :class="isCollapse? 'menu_shrink':''">
          <template slot="title">
            <i :class="item.meta.icon" />
            <span slot="title">{{ item.meta.title }}</span>
          </template>
          <el-submenu v-for="(items,indexs) in item.children" v-show="!items.meta.hidden && isShowMenu2(items)===true" :key="'title1'+ indexs" :index="items.meta.fullPath">
            <i :class="items.meta.icon" />
            <span slot="title">{{ items.meta.title }}</span>
            <el-menu-item v-for="(items2,indexs2) in item.children" v-show="!items2.meta.hidden && isShowMenu(items2)===true" :key="'title2'+ indexs2" :index="items2.meta.fullPath">
              <i :class="items2.meta.icon" />
              <span slot="title">{{ items2.meta.title }}</span>
            </el-menu-item>
          </el-submenu>
          <el-menu-item v-for="(items,indexs3) in item.children" v-show="!items.meta.hidden && isShowMenu(items)===true" :key="'title3'+ indexs3" :index="items.meta.fullPath">
            <i :class="items.meta.icon" />
            <span slot="title">{{ items.meta.title }}</span>
          </el-menu-item>
        </el-submenu>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from './Logo'
import routers from '@/router'
export default {
  components: { Logo },
  data() {
    const WEBURL = 'http://' + window.location.hostname + ':' + location.port + '/Overview/Overview.html'
    const CAMERAURL = 'http://' + window.location.hostname + ':' + location.port + '/Overview/CameraIndex.html'
    const metabaseURL = 'http://101.34.25.130:5030/public/dashboard/d7a7b65f-a2a7-439a-a5ef-8ad1a46e846c'
    return {
      url: WEBURL,
      url1: CAMERAURL,
      url2: metabaseURL,
      routers: routers,
      roles: this.$cookie.get('roles'),
      role: this.$cookie.get('roles').split(',')
    }
  },
  computed: {
    ...mapGetters([
      'sidebar'
    ]),
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo
    },
    isCollapse() {
      return !this.sidebar.opened
    },
    getActiveMenu() {
      return this.$route.meta.fullPath
    }
  },
  methods: {

    isShowMenu(item) {
      const arr = this.roles.split(',')
      if (!item.meta.hidden && arr.includes(item.meta.roles[0])) {
        return true
      }
    },
    isShowMenu2(item) {
      const arr = this.roles.split(',')
      if (!item.meta.hidden && arr.includes(item.meta.roles[0]) && item.children) {
        return true
      }
    },
    isShowParentMenu(routerChildren) {
      const arr = this.roles.split(',')
      // for (const item of routerChildren) {
      //   // if (item.meta.title === '运维指标考核') {
      //   if (!item.meta.hidden && arr.includes(item.meta.roles[0])) {
      //     console.log('iss' + item.meta.title)
      //     // }
      //   }
      // }
      // const isS = routerChildren.filter(x => !x.meta.hidden && x.meta.roles === 'AssessmentRecord')
      // if (isS.length > 0) {
      //   console.log(isS)
      // }

      const isShowMenu = routerChildren.filter(x => !x.meta.hidden && arr.includes(x.meta.roles[0]))
      return isShowMenu.length > 0
    }
  }
}

</script>

<style lang="scss">
/*导航菜单收缩状态*/
.menu_shrink {
  .el-submenu__title {
    font-size: 18px;
    text-align: center;
  }
}
</style>
