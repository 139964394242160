/* Layout */
import Layout from '@/layout'

/* Router Modules */

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin']     所需权限   control the page roles (you can set roles)
    title: 'title'                the name show in sidebar and breadcrumb (recommend set)
    icon: 'icon-name'             the icon show in the sidebar
    noCache: true                 if set true, the page will no be cached(default is false)
    affix: true                   if set true, the tag will affix in the tags-view
    hidden: true                  if set true, item will not show in the sidebar(default is false)
  }
 */

// import emergencyRepairRoutes from './modules/emergencyRepair'
// import devopsAssessRoutes from './modules/devopsAssess'
import integrityRateRoutes from './modules/integrityRate'
import repairStatisticsRoutes from './modules/repairStatistics'
// import maintenanceSuperviseRoutes from './modules/maintenanceSupervise'

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    meta: {
      title: 'redirect',
      hidden: true,
      fullPath: '/redirect'
    },
    children: [
      {
        path: '/redirect/:path*',
        meta: {
          title: 'redirect',
          hidden: true,
          fullPath: '/redirect/:path*'
        },
        component: () => import('@/views/redirect/index')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录',
      hidden: true,
      fullPath: '/login'
    },
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/404',
    name: '404',
    meta: {
      title: '404',
      hidden: true,
      fullPath: '/404'
    },
    component: () => import('@/views/error-page/404'),
    hidden: true
  },
  {
    path: '/401',
    name: '401',
    meta: {
      title: '401',
      hidden: true,
      fullPath: '/401'
    },
    component: () => import('@/views/error-page/401'),
    hidden: true
  }
  // {
  //   path: '/',
  //   name: '/',
  //   meta: {
  //     title: '首页',
  //     icon: 'fa fa-home',
  //     fullPath: '/'
  //   },
  //   component: Layout,
  //   redirect: '/index',
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import('@/views/home/index'),
  //       name: 'index',
  //       meta: {
  //         title: '状态概览',
  //         icon: 'fa fa-home',
  //         fullPath: '/index',
  //         roles: ['EnterSystem'],
  //         affix: true
  //       }
  //     },
  //     {
  //       path: 'myjob',
  //       component: () => import('@/views/home/myjob'),
  //       name: 'myjob',
  //       meta: {
  //         title: '我的工作',
  //         icon: 'fa fa-home',
  //         fullPath: '/myjob',
  //         roles: ['EnterSystem'],
  //         affix: true
  //       }
  //     }
  //   ]
  // }
]

export const asyncRoutes = [
  // 404 page must be placed at the end !!!
  {
    path: '/',
    name: '/',
    meta: {
      title: '首页',
      icon: 'fa fa-desktop',
      fullPath: '/',
      hidden: true
    },
    component: Layout,
    redirect: '/Workorder'
  },
  {
    path: '/fileDown',
    name: 'fileDown',
    meta: {
      title: '文件下载管理',
      hidden: true,
      fullPath: '/fileDown'
    },
    component: Layout,
    redirect: '/fileDown/fileDownIndex',
    children: [
      {
        path: 'fileDownIndex',
        name: 'FileDownindex',
        component: () => import('@/views/fileDown/index'),
        meta: {
          title: '文件下载管理',
          hidden: true,
          roles: ['EnterSystem'],
          fullPath: '/fileDown/fileDownIndex'
        }
      }
    ]
  },
  {
    path: '/view',
    name: 'viewManager',
    meta: {
      title: '可视化分析',
      icon: 'fa fa-tasks',
      fullPath: '/view'
    },
    component: Layout,
    redirect: '/view/viewManager',
    children: [
      {
        path: 'viewManager',
        name: 'viewManager',
        meta: {
          title: '工单分析概览',
          icon: 'fa fa-th-large',
          roles: ['ManageAsset'],
          fullPath: '/view/viewManager'
        },
        component: () => import('@/views/view/viewManager')
      },
      {
        path: 'routineMaintStatistics',
        name: 'RoutineMaintStatistics',
        component: () => import('@/views/view/routineMaintStatistics'),
        meta: {
          roles: ['VisualAnalysis'],
          title: '路网运维概览',
          fullPath: '/view/routineMaintStatistics'
        }
      },
      {
        path: 'routInter',
        name: 'RoutInter',
        component: () => import('@/views/view/routInter'),
        meta: {
          roles: ['VisualAnalysis'],
          title: '缺内运维概览',
          fullPath: '/view/routInter'
        }
      }
      // ,
      // {
      //   path: 'out',
      //   name: 'out',
      //   meta: {
      //     title: '制作',
      //     icon: 'fa fa-th-large',
      //     roles: ['ManageAsset'],
      //     fullPath: '/view/out'
      //   },
      //   component: () => import('@/views/view/out')
      // },
      // {
      //   path: 'show',
      //   name: 'show',
      //   meta: {
      //     title: '展示',
      //     icon: 'fa fa-th-large',
      //     roles: ['ManageAsset'],
      //     fullPath: '/view/show'
      //   },
      //   component: () => import('@/views/view/show')
      // }
    ]
  },
  // 我都工作相关 权限已调整
  {
    path: '/Workorder',
    name: 'Workorder',
    meta: {
      title: '我的工作',
      icon: 'fa fa-desktop',
      fullPath: '/Workorder'
    },
    component: Layout,
    redirect: '/Workorder/Watchmanlist',
    children: [
      {
        path: 'Watchmanlist',
        name: 'WorkorderWatchmanlist',
        component: () => import('@/views/Workorder/Watchmanlist'),
        meta: {
          title: '工作列表',
          icon: 'fa fa-th-large',
          roles: ['EnterSystem'],
          fullPath: '/Workorder/Watchmanlist'
        }
      },
      // {
      //   path: 'Checkedlist',
      //   name: 'WorkorderCheckedlist',
      //   component: () => import('@/views/Workorder/Checkedlist'),
      //   meta: {
      //     title: '已完成工单查询',
      //     icon: 'fa fa-th-large',
      //     roles: ['EnterSystem'],
      //     fullPath: '/Workorder/Checkedlist'
      //   }
      // },
      {
        path: 'JobSearch',
        name: 'JobSearch',
        component: () => import('@/views/Workorder/JobSearch'),
        meta: {
          title: '工单查询',
          icon: 'fa fa-th-large',
          roles: ['EnterSystem'],
          fullPath: '/Workorder/JobSearch'
        }
      },
      {
        path: 'HistoryOrder/:assetId',
        name: 'HistoryOrder',
        component: () => import('@/views/Workorder/JobSearch'),
        meta: {
          title: '历史工单',
          icon: 'fa fa-th-large',
          roles: ['EnterSystem'],
          hidden: true,
          fullPath: '/Workorder/JobSearch'
        }
      },
      {
        path: 'WatchmanAssetslist',
        name: 'WatchmanAssetslist',
        component: () =>
                 import('@/views/Workorder/WatchmanAssetslist'),
        meta: {
          title: '新建报修',
          icon: 'fa fa-th-large',
          roles: ['CreateRepairOrder'],
          fullPath: '/Workorder/WatchmanAssetslist'
        }
      },
      {
        path: 'CheckedInfo/:id',
        name: 'WorkorderCheckedInfo',
        component: () => import('@/views/Workorder/CheckedInfo'),
        meta: {
          title: '工单详情',
          icon: 'fa fa-home',
          roles: ['EnterSystem'],
          hidden: true,
          fullPath: '/Workorder/CheckedInfo'
        }
      },
      {
        path: 'MaintainerRob/:id',
        name: 'WorkorderMaintainerRob',
        component: () =>
                 import('@/views/Workorder/MaintainerRob'),
        meta: {
          title: '维修工程师抢单',
          icon: 'fa fa-home',
          roles: ['GrabOrder'],
          hidden: true,
          fullPath: '/Workorder/MaintainerRob'
        }
      },
      {
        path: 'MaintainerAddRecord/:id',
        name: 'WorkorderMaintainerAddRecord',
        component: () =>
                 import('@/views/Workorder/MaintainerAddRecord'),
        meta: {
          title: '维修工程师添加维修记录',
          icon: 'fa fa-home',
          roles: ['CreateRepairRecord'],
          hidden: true,
          fullPath: '/Workorder/MaintainerAddRecord'
        }
      },
      {
        path: 'MaintainerReceipt/:id',
        name: 'WorkorderMaintainerReceipt',
        component: () =>
                 import('@/views/Workorder/MaintainerReceipt'),
        meta: {
          title: '维修工程师接单',
          icon: 'fa fa-home',
          roles: ['CreateRepairRecord'],
          hidden: true,
          fullPath: '/Workorder/MaintainerReceipt'
        }
      },
      {
        path: 'MaintainerChangeOrder/:id',
        name: 'WorkorderMaintainerChangeOrder',
        component: () =>
                 import('@/views/Workorder/MaintainerChangeOrder'),
        meta: {
          title: '维修工程师转单',
          icon: 'fa fa-home',
          roles: ['CreateRepairRecord'],
          hidden: true,
          fullPath: '/Workorder/MaintainerChangeOrder'
        }
      },
      {
        path: 'AcceptorOperate/:id',
        name: 'WorkorderAcceptorOperate',
        component: () =>
                 import('@/views/Workorder/AcceptorOperate'),
        meta: {
          title: '验收人员验收',
          icon: 'fa fa-home',
          roles: ['CheckRepairRecord'],
          hidden: true,
          fullPath: '/Workorder/AcceptorOperate'
        }
      },
      {
        path: 'AuditorOperate/:id',
        name: 'WorkorderAuditorOperate',
        component: () =>
                 import('@/views/Workorder/AuditorOperate'),
        meta: {
          title: '审核人员审核',
          icon: 'fa fa-home',
          roles: ['ReviewRepairRecord'],
          hidden: true,
          fullPath: '/Workorder/AuditorOperate'
        }
      },
      {
        path: 'WatchmanDispatchSave/:id',
        name: 'MaintenanceWatchmanDispatchSave',
        component: () =>
                 import('@/views/Workorder/WatchmanDispatchSave'),
        meta: {
          title: '值班人员工单编辑',
          icon: 'fa fa-home',
          roles: ['Staff'],
          hidden: true,
          fullPath: '/Workorder/WatchmanDispatchSave'
        }
      },
      {
        path: 'WatchmanDispatch/:id',
        name: 'MaintenanceWatchmanDispatch',
        component: () =>
                 import('@/views/Workorder/WatchmanDispatch'),
        meta: {
          title: '值班人员派单',
          icon: 'fa fa-home',
          roles: ['Staff'],
          hidden: true,
          fullPath: '/Workorder/WatchmanDispatch'
        }
      },
      {
        path: 'WatchmanDispatchs/:id',
        name: 'MaintenanceWatchmanDispatchs',
        component: () =>
                 import('@/views/Workorder/WatchmanDispatchs'),
        meta: {
          title: '值班人员派单',
          icon: 'fa fa-home',
          roles: ['Staff'],
          hidden: true,
          fullPath: '/Workorder/WatchmanDispatchs'
        }
      }
    ]
  },
  // 资产相关 权限已调整
  {
    path: '/assets',
    name: 'assets',
    meta: {
      title: '资产管理',
      icon: 'fa fa-tasks',
      fullPath: '/assets'
    },
    component: Layout,
    redirect: '/assets/list',
    children: [
      // {
      //   path: 'AssetsView',
      //   name: 'AssetsView',
      //   component: () => import('@/views/assets/AssetsView'),
      //   meta: {
      //     title: '资产概览',
      //     icon: 'fa fa-th-large',
      //     roles: ['ManageAsset'],
      //     fullPath: '/assets/AssetsView'
      //   }
      // },
      {
        path: 'list',
        name: 'Assetslist',
        meta: {
          title: '资产列表',
          icon: 'fa fa-th-large',
          roles: ['ViewAsset'],
          fullPath: '/assets/list'
        },
        component: () => import('@/views/assets/list')
      },
      {
        path: 'Info/:id',
        name: 'AssetsInfo',
        meta: {
          title: '资产明细',
          icon: 'fa fa-home',
          roles: ['ManageAsset'],
          fullPath: '/assets/Info/',
          hidden: true
        },
        component: () => import('@/views/assets/Info')
      },
      {
        path: 'Create',
        name: 'AssetsCreate',
        meta: {
          title: '新增资产',
          icon: 'fa fa-th-large',
          roles: ['ManageAsset'],
          fullPath: '/assets/Create'
        },
        component: () => import('@/views/assets/Create')
      },
      {
        path: 'AssetField',
        name: 'AssetsAssetField',
        meta: {
          title: '资产自定义属性',
          icon: 'fa fa-th-large',
          roles: ['ManageAsset'],
          fullPath: '/assets/AssetField'
        },
        component: () => import('@/views/assets/AssetField')
      }
    ]
  },
  {
    path: '/assetGovernance',
    name: 'assetGovernance',
    meta: {
      title: '资产治理',
      icon: 'fa fa-tasks',
      fullPath: '/assetGovernance'
    },
    component: Layout,
    redirect: '/assetGovernance/locationGovernance',
    children: [
      {
        path: 'impexpAssetLst',
        name: 'ImpexpAssetLst',
        meta: {
          title: '资产治理数据导入',
          icon: 'fa fa-th-large',
          roles: ['AssetGovernance'],
          fullPath: '/assetGovernance/impexpAssetLst'
        },
        component: () => import('@/views/assetGovernance/impexpAssetLst')
      },
      {
        path: 'locationGovernance',
        name: 'LocationGovernance',
        meta: {
          title: '位置治理',
          icon: 'fa fa-th-large',
          hidden: true,
          roles: ['AssetGovernance'],
          fullPath: '/assetGovernance/locationGovernance'
        },
        component: () => import('@/views/assetGovernance/locationGovernance')
      },
      {
        path: 'assetDetail',
        name: 'AssetDetail',
        meta: {
          title: '详情',
          hidden: true,
          icon: 'fa fa-th-large',
          roles: ['EnterSystem'],
          fullPath: '/assetGovernance/assetDetail'
        },
        component: () => import('@/views/assetGovernance/assetDetail')
      },
      {
        path: 'devNmManagement',
        name: 'DevNmManagement',
        meta: {
          title: '设备名称治理',
          icon: 'fa fa-th-large',
          hidden: true,
          roles: ['AssetGovernance'],
          fullPath: '/assetGovernance/devNmManagement'
        },
        component: () => import('@/views/assetGovernance/devNmManagement')
      },
      {
        path: 'assetGovernance',
        name: 'AssetGovernance',
        meta: {
          title: '资产治理',
          icon: 'fa fa-th-large',
          hidden: true,
          roles: ['AssetGovernance'],
          fullPath: '/assetGovernance/assetGovernance'
        },
        component: () => import('@/views/assetGovernance/assetGovernance')
      },
      {
        path: 'assetResultsView',
        name: 'AssetResultsView',
        meta: {
          title: '资产治理结果查看',
          icon: 'fa fa-th-large',
          hidden: true,
          roles: ['AssetGovernance'],
          fullPath: '/assetGovernance/assetResultsView'
        },
        component: () => import('@/views/assetGovernance/assetResultsView')
      },
      {
        path: 'impexpAssetLstCheck',
        name: 'ImpexpAssetLstCheck',
        meta: {
          title: '资产治理数据审核',
          icon: 'fa fa-th-large',
          roles: ['AssetGovernanceReview'],
          fullPath: '/assetGovernance/impexpAssetLstCheck'
        },
        component: () => import('@/views/assetGovernance/impexpAssetLstCheck')
      }
    ]
  },
  {
    path: '/claimsManagement',
    name: 'claimsManagement',
    meta: {
      title: '索赔管理',
      icon: 'fa fa-tasks',
      fullPath: '/claimsManagement'
    },
    component: Layout,
    redirect: '/claimsManagement/claimLst',
    children: [
      {
        path: 'claimLst',
        name: 'ClaimLst',
        meta: {
          title: '索赔管理列表',
          icon: 'fa fa-th-large',
          roles: ['CompensationForm'],
          fullPath: '/claimsManagement/claimLst'
        },
        component: () => import('@/views/claimsManagement/claimLst')
      },
      {
        path: 'addClaimForm',
        name: 'AddClaimForm',
        meta: {
          title: '索赔单添加',
          icon: 'fa fa-th-large',
          hidden: true,
          roles: ['CreateCompensation'],
          fullPath: '/claimsManagement/addClaimForm'
        },
        component: () => import('@/views/claimsManagement/addClaimForm')
      },
      {
        path: 'editClaimForm',
        name: 'AddClaimForm',
        meta: {
          title: '索赔单修改',
          icon: 'fa fa-th-large',
          hidden: true,
          roles: ['CreateCompensation'],
          fullPath: '/claimsManagement/editClaimForm'
        },
        component: () => import('@/views/claimsManagement/addClaimForm')
      },
      {
        path: 'claimFormDetail',
        name: 'AddClaimForm',
        meta: {
          title: '索赔单详情',
          icon: 'fa fa-th-large',
          hidden: true,
          roles: ['CompensationForm'],
          fullPath: '/claimsManagement/claimFormDetail'
        },
        component: () => import('@/views/claimsManagement/addClaimForm')
      },
      {
        path: 'claimRvwLst',
        name: 'ClaimRvwLst',
        meta: {
          title: '索赔审核列表',
          icon: 'fa fa-th-large',
          roles: ['CompensationReview'],
          fullPath: '/claimsManagement/claimRvwLst'
        },
        component: () => import('@/views/claimsManagement/claimRvwLst')
      },
      {
        path: 'claimFormAudit',
        name: 'AddClaimForm',
        meta: {
          title: '索赔单审核详情',
          icon: 'fa fa-th-large',
          hidden: true,
          roles: ['CompensationReview'],
          fullPath: '/claimsManagement/claimFormAudit'
        },
        component: () => import('@/views/claimsManagement/addClaimForm')
      },
      {
        path: 'claimStatistics',
        name: 'ClaimStatistics',
        meta: {
          title: '索赔单概览',
          icon: 'fa fa-th-large',
          roles: ['CompensationStatic'],
          fullPath: '/claimsManagement/claimStatistics'
        },
        component: () => import('@/views/claimsManagement/claimStatistics')
      },
      {
        path: 'claimStatisticsView',
        name: 'claimStatisticsView',
        meta: {
          title: '索赔单统计',
          icon: 'fa fa-th-large',
          roles: ['CompensationStatic'],
          fullPath: '/claimsManagement/claimStatisticsView'
        },
        component: () => import('@/views/claimsManagement/claimStatisticsView')
      }
      // {
      //   path: 'assetDetail',
      //   name: 'AssetDetail',
      //   meta: {
      //     title: '详情',
      //     hidden: true,
      //     icon: 'fa fa-th-large',
      //     roles: ['ManageAsset'],
      //     fullPath: '/assetGovernance/assetDetail'
      //   },
      //   component: () => import('@/views/assetGovernance/assetDetail')
      // },
      // {
      //   path: 'devNmManagement',
      //   name: 'DevNmManagement',
      //   meta: {
      //     title: '设备名称治理',
      //     icon: 'fa fa-th-large',
      //     hidden: true,
      //     roles: ['ManageAsset'],
      //     fullPath: '/assetGovernance/devNmManagement'
      //   },
      //   component: () => import('@/views/assetGovernance/devNmManagement')
      // },
      // {
      //   path: 'assetGovernance',
      //   name: 'AssetGovernance',
      //   meta: {
      //     title: '资产治理',
      //     icon: 'fa fa-th-large',
      //     hidden: true,
      //     roles: ['ManageAsset'],
      //     fullPath: '/assetGovernance/assetGovernance'
      //   },
      //   component: () => import('@/views/assetGovernance/assetGovernance')
      // },
      // {
      //   path: 'assetResultsView',
      //   name: 'AssetResultsView',
      //   meta: {
      //     title: '资产治理结果查看',
      //     icon: 'fa fa-th-large',
      //     hidden: true,
      //     roles: ['ManageAsset'],
      //     fullPath: '/assetGovernance/assetResultsView'
      //   },
      //   component: () => import('@/views/assetGovernance/assetResultsView')
      // },
      // {
      //   path: 'impexpAssetLstCheck',
      //   name: 'ImpexpAssetLstCheck',
      //   meta: {
      //     title: '资产治理数据审核',
      //     icon: 'fa fa-th-large',
      //     roles: ['ManageAsset'],
      //     fullPath: '/assetGovernance/impexpAssetLstCheck'
      //   },
      //   component: () => import('@/views/assetGovernance/impexpAssetLstCheck')
      // }
    ]
  },
  {
    path: '/engineeringsManagement',
    name: 'engineeringsManagement',
    meta: {
      title: '工程管理',
      icon: 'fa fa-tasks',
      fullPath: '/engineeringsManagement'
    },
    component: Layout,
    redirect: '/engineeringsManagement/engineeringLst',
    children: [
      {
        path: 'engineeringLst',
        name: 'EngineeringLst',
        meta: {
          title: '工程管理列表',
          icon: 'fa fa-th-large',
          roles: ['ProjectLst'],
          fullPath: '/engineeringsManagement/engineeringLst'
        },
        component: () => import('@/views/engineeringsManagement/engineeringLst')
      },
      {
        path: 'addEngineeringForm',
        name: 'AddEngineeringForm',
        meta: {
          title: '工程管理添加',
          icon: 'fa fa-th-large',
          hidden: true,
          roles: ['NewProject'],
          fullPath: '/engineeringsManagement/addEngineeringForm'
        },
        component: () => import('@/views/engineeringsManagement/addEngineeringForm')
      },
      {
        path: 'editEngineeringForm',
        name: 'AddEngineeringForm',
        meta: {
          title: '工程管理修改',
          icon: 'fa fa-th-large',
          hidden: true,
          roles: ['EngineeringOperations'],
          fullPath: '/engineeringsManagement/editEngineeringForm'
        },
        component: () => import('@/views/engineeringsManagement/addEngineeringForm')
      },
      {
        path: 'engineeringFormDetail',
        name: 'AddEngineeringForm',
        meta: {
          title: '工程管理详情',
          icon: 'fa fa-th-large',
          hidden: true,
          roles: ['EngineeringView'],
          fullPath: '/engineeringsManagement/engineeringFormDetail'
        },
        component: () => import('@/views/engineeringsManagement/addEngineeringForm')
      },
      {
        path: 'engineeringRvwLst',
        name: 'EngineeringRvwLst',
        meta: {
          title: '工程管理审核列表',
          icon: 'fa fa-th-large',
          roles: ['EngineeringAdult'],
          fullPath: '/engineeringsManagement/engineeringRvwLst'
        },
        component: () => import('@/views/engineeringsManagement/engineeringRvwLst')
      },
      {
        path: 'engineeringFormAudit',
        name: 'AddEngineeringForm',
        meta: {
          title: '工程管理审核详情',
          icon: 'fa fa-th-large',
          hidden: true,
          roles: ['EngineeringAdult'],
          fullPath: '/engineeringsManagement/engineeringFormAudit'
        },
        component: () => import('@/views/engineeringsManagement/addEngineeringForm')
      },
      {
        path: 'engineeringConfirmLst',
        name: 'EngineeringConfirmLst',
        meta: {
          title: '工程确认',
          icon: 'fa fa-th-large',
          roles: ['EngineeringConfirmation'],
          fullPath: '/engineeringsManagement/engineeringConfirmLst'
        },
        component: () => import('@/views/engineeringsManagement/engineeringConfirmLst')
      },
      {
        path: 'checkEntryForm',
        name: 'CheckEntryForm',
        meta: {
          title: '检查录入',
          icon: 'fa fa-th-large',
          hidden: true,
          roles: ['CheckEntry'],
          fullPath: '/engineeringsManagement/checkEntryForm'
        },
        component: () => import('@/views/engineeringsManagement/checkEntryForm')
      },
      {
        path: 'engineeringconstructionLst',
        name: 'EngineeringconstructionLst',
        meta: {
          title: '工程施工',
          icon: 'fa fa-th-large',
          roles: ['EngineeringLog'],
          fullPath: '/engineeringsManagement/engineeringconstructionLst'
        },
        component: () => import('@/views/engineeringsManagement/engineeringconstructionLst')
      },
      {
        path: 'constructionForm',
        name: 'ConstructionForm',
        meta: {
          title: '施工日志',
          icon: 'fa fa-th-large',
          hidden: true,
          roles: ['AddEngineeringLog'],
          fullPath: '/engineeringsManagement/constructionForm'
        },
        component: () => import('@/views/engineeringsManagement/constructionForm')
      },
      {
        path: 'engineeringStatistics',
        name: 'EngineeringStatistics',
        meta: {
          title: '工程统计',
          icon: 'fa fa-th-large',
          roles: ['EngineeringStatistics'],
          fullPath: '/engineeringsManagement/engineeringStatistics'
        },
        component: () => import('@/views/engineeringsManagement/engineeringStatistics')
      }
    ]
  },
  // 罩棚相关 权限已调整
  {
    path: '/TollCanopy',
    name: 'TollCanopy',
    meta: {
      title: '收费罩棚管理',
      icon: 'fa fa-tasks',
      fullPath: '/TollCanopy'
    },
    component: Layout,
    redirect: '/TollCanopy/list',
    children: [
      {
        path: 'TollCanopyList',
        name: 'TollCanopyList',
        meta: {
          title: '收费罩棚列表',
          icon: 'fa fa-th-large',
          roles: ['TollCanopyManage'],
          // hidden: true,
          fullPath: '/TollCanopy/TollCanopyList'
        },
        component: () => import('@/views/TollCanopy/TollCanopyList')
      },
      {
        path: 'TollCanopyView/:id',
        name: 'TollCanopyView',
        component: () => import('@/views/TollCanopy/TollCanopyView'),
        meta: {
          title: '收费罩棚资产详情',
          icon: 'fa fa-home',
          roles: ['TollCanopyManage'],
          hidden: true,
          fullPath: '/TollCanopy/TollCanopyView'
        }
      },
      {
        path: 'TollCanopyDetailEdit/:id',
        name: 'TollCanopyDetail',
        component: () =>
               import('@/views/TollCanopy/TollCanopyDetail'),
        meta: {
          title: '收费罩棚资产编辑',
          icon: 'fa fa-home',
          roles: ['TollCanopyManage'],
          hidden: true,
          fullPath: '/TollCanopy/TollCanopyDetail'
        }
      },
      {
        path: 'TollCanopyDetail',
        name: 'TollCanopyDetail',
        component: () =>
               import('@/views/TollCanopy/TollCanopyDetail'),
        meta: {
          title: '收费罩棚资产详情',
          icon: 'fa fa-home',
          roles: ['TollCanopyManage'],
          hidden: true,
          fullPath: '/TollCanopy/TollCanopyDetail'
        }
      },
      {
        path: 'dlyInspectionRecords',
        name: 'DlyInspectionRecords',
        component: () =>
               import('@/views/TollCanopy/dlyInspectionRecords'),
        meta: {
          title: '新增收费罩棚日常巡查记录表',
          icon: 'fa fa-home',
          roles: ['TollCanopyManage'],
          hidden: true,
          fullPath: '/TollCanopy/dlyInspectionRecords'
        }
      },
      {
        path: 'dlyInspectionRecordDetail',
        name: 'DlyInspectionRecordDetail',
        component: () =>
               import('@/views/TollCanopy/dlyInspectionRecordDetail'),
        meta: {
          title: '收费罩棚日常巡查记录表查看',
          icon: 'fa fa-home',
          roles: ['TollCanopyManage'],
          hidden: true,
          fullPath: '/TollCanopy/dlyInspectionRecordDetail'
        }
      },
      {
        path: 'dlyInspectionRecShet',
        name: 'DlyInspectionRecShet',
        component: () =>
               import('@/views/TollCanopy/dlyInspectionRecShet'),
        meta: {
          title: '收费罩棚日常检查记录表',
          icon: 'fa fa-home',
          roles: ['TollCanopyManage'],
          hidden: true,
          fullPath: '/TollCanopy/dlyInspectionRecShet'
        }
      },
      {
        path: 'dlyInspectionRecShetDetail',
        name: 'DlyInspectionRecShetDetail',
        component: () =>
               import('@/views/TollCanopy/dlyInspectionRecShetDetail'),
        meta: {
          title: '收费罩棚日常检查记录表查看',
          icon: 'fa fa-home',
          roles: ['TollCanopyManage'],
          hidden: true,
          fullPath: '/TollCanopy/dlyInspectionRecShetDetail'
        }
      },
      {
        path: 'PeriodicInspectionRecForm',
        name: 'PeriodicInspectionRecForm',
        component: () =>
               import('@/views/TollCanopy/PeriodicInspectionRecForm'),
        meta: {
          title: '收费罩棚定期检查记录表',
          icon: 'fa fa-home',
          roles: ['TollCanopyManage'],
          hidden: true,
          fullPath: '/TollCanopy/PeriodicInspectionRecForm'
        }
      },
      {
        path: 'PeriodicInspectionRecFormDetail',
        name: 'PeriodicInspectionRecFormDetail',
        component: () =>
               import('@/views/TollCanopy/PeriodicInspectionRecFormDetail'),
        meta: {
          title: '收费罩棚定期检查记录表查看',
          icon: 'fa fa-home',
          roles: ['TollCanopyManage'],
          hidden: true,
          fullPath: '/TollCanopy/PeriodicInspectionRecFormDetail'
        }
      },
      {
        path: 'emrgInspectionRecForm',
        name: 'EmrgInspectionRecForm',
        component: () =>
               import('@/views/TollCanopy/emrgInspectionRecForm'),
        meta: {
          title: '收费罩棚应急检查记录表',
          icon: 'fa fa-home',
          roles: ['TollCanopyManage'],
          hidden: true,
          fullPath: '/TollCanopy/emrgInspectionRecForm'
        }
      },
      {
        path: 'emrgInspectionRecFormDetail',
        name: 'EmrgInspectionRecFormDetail',
        component: () =>
               import('@/views/TollCanopy/emrgInspectionRecFormDetail'),
        meta: {
          title: '收费罩棚应急检查记录表查看',
          icon: 'fa fa-home',
          roles: ['TollCanopyManage'],
          hidden: true,
          fullPath: '/TollCanopy/emrgInspectionRecFormDetail'
        }
      },
      {
        path: 'ProfessionalInspectionRecForm',
        name: 'ProfessionalInspectionRecForm',
        component: () =>
               import('@/views/TollCanopy/ProfessionalInspectionRecForm'),
        meta: {
          title: '收费罩棚专业检查记录表',
          icon: 'fa fa-home',
          roles: ['TollCanopyManage'],
          hidden: true,
          fullPath: '/TollCanopy/ProfessionalInspectionRecForm'
        }
      },
      {
        path: 'ProfessionalInspectionRecFormDetail',
        name: 'ProfessionalInspectionRecFormDetail',
        component: () =>
               import('@/views/TollCanopy/ProfessionalInspectionRecFormDetail'),
        meta: {
          title: '收费罩棚专业检查记录表查看',
          icon: 'fa fa-home',
          roles: ['TollCanopyManage'],
          hidden: true,
          fullPath: '/TollCanopy/ProfessionalInspectionRecFormDetail'
        }
      },
      {
        path: 'InspectionRecLst',
        name: 'InspectionRecLst',
        meta: {
          title: '收费罩棚巡检记录列表',
          icon: 'fa fa-th-large',
          roles: ['TollCanopyManage'],
          // hidden: true,
          fullPath: '/TollCanopy/InspectionRecLst'
        },
        component: () => import('@/views/TollCanopy/InspectionRecLst')
      },
      {
        path: 'TechnicalStsAssessmentForm',
        name: 'TechnicalStsAssessmentForm',
        meta: {
          title: '收费罩棚构件技术状况评定表',
          icon: 'fa fa-th-large',
          roles: ['TollCanopyManage'],
          hidden: true,
          fullPath: '/TollCanopy/TechnicalStsAssessmentForm'
        },
        component: () => import('@/views/TollCanopy/TechnicalStsAssessmentForm')
      },
      {
        path: 'TechnicalStsAssessmentFormDetail',
        name: 'TechnicalStsAssessmentFormDetail',
        meta: {
          title: '收费罩棚构件技术状况评定表查看',
          icon: 'fa fa-th-large',
          roles: ['TollCanopyManage'],
          hidden: true,
          fullPath: '/TollCanopy/TechnicalStsAssessmentFormDetail'
        },
        component: () => import('@/views/TollCanopy/TechnicalStsAssessmentFormDetail')
      },
      {
        path: 'StructuralTechnicalStatusDetail',
        name: 'StructuralTechnicalStatusDetail',
        meta: {
          title: '收费罩棚结构技术状况评定表查看',
          icon: 'fa fa-th-large',
          roles: ['TollCanopyManage'],
          hidden: true,
          fullPath: '/TollCanopy/StructuralTechnicalStatusDetail'
        },
        component: () => import('@/views/TollCanopy/StructuralTechnicalStatusDetail')
      }
    ]
  },
  // 清单相关
  {
    path: '/Inventory',
    name: 'Inventory',
    meta: {
      title: '清单管理',
      icon: 'fa fa-sitemap',
      fullPath: '/Inventory'
    },
    component: Layout,
    redirect: '/Inventory/List',
    children: [
      {
        path: 'list',
        name: 'Inventorylist',
        meta: {
          title: '清单列表',
          icon: 'fa fa-th-large',
          roles: ['EquipmentList'],
          fullPath: '/Inventory/List'
        },
        component: () => import('@/views/Inventory/List')
      },
      {
        path: 'Info/:id',
        name: 'InventoryInfo',
        meta: {
          title: '清单详情',
          icon: 'fa fa-home',
          roles: ['EquipmentList'],
          hidden: true,
          fullPath: '/Inventory/Info'
        },
        component: () => import('@/views/Inventory/Info')
      },
      {
        path: 'Update/:id',
        name: 'InventoryUpdate',
        meta: {
          title: '生成清单详情',
          icon: 'fa fa-home',
          roles: ['EquipmentList'],
          hidden: true,
          fullPath: '/Inventory/Update'
        },
        component: () => import('@/views/Inventory/Update')
      }
    ]
  },
  // 备品备件相关
  {
    path: '/SparePart',
    name: '/SparePart',
    meta: {
      title: '备品备件',
      icon: 'fa fa-plug',
      fullPath: '/SparePart'
    },
    component: Layout,
    redirect: '/SparePart/List',
    children: [
      // {
      //   path: 'StockView',
      //   name: 'StockViewList',
      //   component: () => import('@/views/SparePart/StockView'),
      //   meta: {
      //     title: '备品备件概览',
      //     icon: 'fa fa-th-large',
      //     roles: ['SpareNameManage'],
      //     fullPath: '/SparePart/StockView'
      //   }
      // },
      {
        path: 'SparePartList',
        name: 'SparePartListList',
        component: () =>
                 import('@/views/SparePart/sparePartList'),
        meta: {
          title: '备品备件管理',
          icon: 'fa fa-th-large',
          roles: ['SpareNameManage'],
          fullPath: '/SparePart/sparePartList'
        }
      },
      {
        path: 'StoreroomList',
        name: 'StoreroomListList',
        component: () =>
                 import('@/views/SparePart/StoreroomList'),
        meta: {
          title: '库房管理',
          icon: 'fa fa-th-large',
          roles: ['SpareRepositoryManage'],
          fullPath: '/SparePart/StoreroomList'
        }
      },
      {
        path: 'InstantInventory',
        name: 'InstantInventoryList',
        component: () =>
                 import('@/views/SparePart/InstantInventory'),
        meta: {
          title: '即时库存',
          icon: 'fa fa-th-large',
          roles: ['SpareStockManage'],
          fullPath: '/SparePart/InstantInventory'
        }
      },
      {
        path: 'InstockList',
        name: 'InstockListList',
        component: () => import('@/views/SparePart/InstockList'),
        meta: {
          title: '入库单查看',
          icon: 'fa fa-th-large',
          roles: ['SpareInboundManage'],
          fullPath: '/SparePart/InstockList'
        }
      },
      {
        path: 'OutstockList',
        name: 'OutstockListList',
        component: () => import('@/views/SparePart/OutstockList'),
        meta: {
          title: '出库单查看',
          icon: 'fa fa-th-large',
          roles: ['SpareOutboundManage'],
          fullPath: '/SparePart/OutstockList'
        }
      },
      {
        path: 'AllocationList',
        name: 'AllocationListList',
        component: () =>
                 import('@/views/SparePart/AllocationList'),
        meta: {
          title: '调拨申请',
          icon: 'fa fa-th-large',
          roles: ['TransferApplication'],
          fullPath: '/SparePart/AllocationList'
        }
      },
      {
        path: 'AllocationCheck',
        name: 'AllocationCheckList',
        component: () =>
                 import('@/views/SparePart/AllocationCheck'),
        meta: {
          title: '调拨申请单审批',
          icon: 'fa fa-th-large',
          roles: ['TransferApplicationReview'],
          fullPath: '/SparePart/AllocationCheck'
        }
      }
    ]
  },
  // 考核管理
  {
    path: '/assessmentManagement',
    name: '/assessmentManagement',
    meta: {
      title: '考核管理',
      icon: 'fa fa-plug',
      fullPath: '/assessmentManagement'
    },
    component: Layout,
    redirect: '/assessmentManagement/funcPoints',
    children: [
      {
        path: 'funcPoints',
        name: 'FuncPoints',
        component: () => import('@/views/assessmentManagement/funcPoints'),
        meta: {
          title: '功能点管理',
          icon: 'fa fa-th-large',
          roles: ['Assessment'],
          fullPath: '/assessmentManagement/funcPoints'
        }
      },
      {
        path: 'assessmentCoefficient',
        name: 'AssessmentCoefficient',
        component: () => import('@/views/assessmentManagement/assessmentCoefficient'),
        meta: {
          title: '考核系数管理',
          icon: 'fa fa-th-large',
          roles: ['Assessment'],
          fullPath: '/assessmentManagement/assessmentCoefficient'
        }
      },
      {
        path: 'assetsmentLst',
        name: 'AssetsmentLst',
        component: () => import('@/views/assessmentManagement/assetsmentLst'),
        meta: {
          title: '月度考核',
          icon: 'fa fa-th-large',
          roles: ['Assessment'],
          fullPath: '/assessmentManagement/assetsmentLst'
        }
      },
      {
        path: 'assetsmentDetail',
        name: 'AssetsmentDetail',
        component: () => import('@/views/assessmentManagement/assetsmentDetail'),
        meta: {
          title: '月度考核详情',
          hidden: true,
          icon: 'fa fa-th-large',
          roles: ['Assessment'],
          fullPath: '/assessmentManagement/assetsmentDetail'
        }
      }
    ]
  },
  // 资产报损
  {
    path: '/ReportDamages',
    name: 'ReportDamages',
    meta: {
      title: '资产报损',
      icon: 'fa fa-home',
      fullPath: '/ReportDamages'
    },
    component: Layout,
    redirect: '/ReportDamages/ReportApply',
    children: [
      {
        path: 'ReportApply',
        name: 'ReportApply',
        component: () =>
                 import('@/views/ReportDamages/ReportApply'),
        meta: {
          title: '报损管理',
          icon: 'fa fa-th-large',
          roles: ['ReportBreakage'],
          fullPath: '/ReportDamages/ReportApply'
        }
      },
      {
        path: 'ReportApplyCopy',
        name: 'ReportApplyCopy',
        component: () =>
                 import('@/views/ReportDamages/ReportApplyCopy'),
        meta: {
          title: '新报损管理',
          icon: 'fa fa-th-large',
          roles: ['ReportBreakage'],
          fullPath: '/ReportDamages/ReportApplyCopy'
        }
      },
      {
        path: 'ReportApplyDetail/:ReapirCode',
        name: 'ReportApplyDetail',
        component: () =>
                 import('@/views/ReportDamages/ReportApplyDetail'),
        meta: {
          title: '添加报损申请',
          icon: 'fa fa-th-large',
          hidden: true,
          roles: ['ReportBreakage'],
          fullPath: '/ReportDamages/ReportApplyDetail'
        }
      },
      {
        path: 'ReportApplyDetailCopy/:ReapirCode',
        name: 'ReportApplyDetailCopy',
        component: () =>
                 import('@/views/ReportDamages/ReportApplyDetailCopy'),
        meta: {
          title: '添加报损申请',
          icon: 'fa fa-th-large',
          hidden: true,
          roles: ['ReportBreakage'],
          fullPath: '/ReportDamages/ReportApplyDetailCopy'
        }
      },
      {
        path: 'ReportApplyInfo/:id',
        name: 'ReportApplyInfo',
        component: () =>
                 import('@/views/ReportDamages/ReportApplyInfo'),
        meta: {
          title: '报损申请详情',
          hidden: true,
          icon: 'fa fa-th-large',
          roles: ['ReportBreakage'],
          fullPath: '/ReportDamages/ReportApplyInfo'
        }
      },
      {
        path: 'ReportApplyInfoCopy/:id',
        name: 'ReportApplyInfoCopy',
        component: () =>
                 import('@/views/ReportDamages/ReportApplyInfoCopy'),
        meta: {
          title: '报损申请详情',
          hidden: true,
          icon: 'fa fa-th-large',
          roles: ['ReportBreakage'],
          fullPath: '/ReportDamages/ReportApplyInfoCopy'
        }
      },
      {
        path: 'ReportApplyEdit/:id',
        name: 'ReportApplyEdit',
        component: () =>
                 import('@/views/ReportDamages/ReportApplyEdit'),
        meta: {
          title: '报损申请编辑',
          icon: 'fa fa-th-large',
          hidden: true,
          roles: ['ReportBreakage'],
          fullPath: '/ReportDamages/ReportApplyEdit'
        }
      },
      {
        path: 'ReportApplyOperate/:id',
        name: 'ReportApplyOperate',
        component: () =>
                 import('@/views/ReportDamages/ReportApplyOperate'),
        meta: {
          title: '报损申请审批',
          icon: 'fa fa-th-large',
          hidden: true,
          roles: ['ReportBreakageAudit'],
          fullPath: '/ReportDamages/ReportApplyOperate'
        }
      },
      {
        path: 'ReportApplyOperateCopy/:id',
        name: 'ReportApplyOperateCopy',
        component: () =>
                 import('@/views/ReportDamages/ReportApplyOperateCopy'),
        meta: {
          title: '报损申请审批',
          icon: 'fa fa-th-large',
          hidden: true,
          roles: ['ReportBreakageAudit'],
          fullPath: '/ReportDamages/ReportApplyOperateCopy'
        }
      }
    ]
  },
  // 运维管理相关 权限已调整
  // {
  //   path: '/maintenance',
  //   name: 'maintenance',
  //   meta: {
  //     title: '运维管理',
  //     icon: 'fa fa-wrench',
  //     fullPath: '/maintenance'
  //   },
  //   component: Layout,
  //   redirect: '/maintenance/WatchmanAssetslist',
  //   children: [
  //     }
  //   ]
  // },
  // 维护管理相关
  {
    path: '/Repairmanage',
    name: '/Repairmanage',
    meta: {
      title: '维护管理',
      icon: 'fa fa-puzzle-piece',
      fullPath: '/Repairmanage'
    },
    component: Layout,
    redirect: '/Repairmanage/List',
    children: [
      {
        path: 'PlanList',
        name: 'PlanList',
        component: () => import('@/views/Repairmanage/PlanList'),
        meta: {
          title: '年维护计划列表',
          icon: 'fa fa-th-large',
          roles: ['MaintenancePlan'],
          fullPath: '/Repairmanage/PlanList'
        }
      },
      {
        path: 'PlanDetail',
        name: 'PlanDetail',
        component: () =>
                 import('@/views/Repairmanage/PlanDetail'),
        meta: {
          title: '年维护计划详情',
          icon: 'fa fa-th-large',
          hidden: true,
          roles: ['MaintenancePlan'],
          fullPath: '/Repairmanage/PlanDetail'
        }
      },
      {
        path: 'PlanListCheck',
        name: 'PlanListCheck',
        component: () => import('@/views/Repairmanage/PlanListCheck'),
        meta: {
          title: '年维护计划审核',
          icon: 'fa fa-th-large',
          roles: ['MaintenancePlan'],
          fullPath: '/Repairmanage/PlanListCheck'
        }
      },
      {
        path: 'MonthPlanList',
        name: 'MonthPlanList',
        component: () =>
                 import('@/views/Repairmanage/MonthPlanList'),
        meta: {
          title: '月维护计划列表',
          icon: 'fa fa-th-large',
          roles: ['MaintenancePlan'],
          fullPath: '/Repairmanage/MonthPlanList'
        }
      },
      {
        path: 'MonthPlanDetail',
        name: 'MonthPlanDetail',
        component: () => import('@/views/Repairmanage/MonthPlanDetail'),
        meta: {
          title: '月维护计划详情',
          hidden: true,
          icon: 'fa fa-th-large',
          roles: ['MaintenancePlan'],
          fullPath: '/Repairmanage/MonthPlanDetail'
        }
      },
      {
        path: 'MonthPlanListCheck',
        name: 'MonthPlanListCheck',
        component: () => import('@/views/Repairmanage/MonthPlanListCheck'),
        meta: {
          title: '月维护计划审核',
          icon: 'fa fa-th-large',
          roles: ['MaintenancePlan'],
          fullPath: '/Repairmanage/MonthPlanListCheck'
        }
      },
      {
        path: 'EntryRecord',
        name: 'EntryRecord',
        component: () => import('@/views/Repairmanage/EntryRecord'),
        meta: {
          title: '录入维护记录',
          icon: 'fa fa-th-large',
          roles: ['MaintenancePlan'],
          fullPath: '/Repairmanage/EntryRecord'
        }
      },
      {
        path: 'CheckRecord',
        name: 'CheckRecord',
        component: () => import('@/views/Repairmanage/CheckRecord'),
        meta: {
          title: '验收维护记录',
          icon: 'fa fa-th-large',
          roles: ['MaintenancePlan'],
          fullPath: '/Repairmanage/CheckRecord'
        }
      },
      {
        path: 'SearchRecord',
        name: 'SearchRecord',
        component: () => import('@/views/Repairmanage/SearchRecord'),
        meta: {
          title: '查询维护记录',
          icon: 'fa fa-th-large',
          roles: ['MaintenancePlan'],
          fullPath: '/Repairmanage/SearchRecord'
        }
      },
      {
        path: 'PlanView',
        name: 'PlanView',
        component: () => import('@/views/Repairmanage/PlanView'),
        meta: {
          title: '维护统计',
          icon: 'fa fa-th-large',
          roles: ['MaintenancePlan'],
          fullPath: '/Repairmanage/PlanView'
        }
      },
      {
        path: 'RepairtaskList',
        name: 'RepairtaskListList',
        component: () =>
                 import('@/views/Repairmanage/RepairtaskList'),
        meta: {
          title: '维护任务',
          icon: 'fa fa-th-large',
          roles: ['MaintenanceTask'],
          fullPath: '/Repairmanage/RepairtaskList'
        }
      },
      {
        path: 'RepaircheckList',
        name: 'RepaircheckListList',
        component: () =>
                 import('@/views/Repairmanage/RepaircheckList'),
        meta: {
          title: '维护验收',
          icon: 'fa fa-th-large',
          roles: ['MaintenanceReview'],
          fullPath: '/Repairmanage/RepaircheckList'
        }
      }
    ]
  },
  {
    path: '/QuotaDataManagement',
    name: '/QuotaDataManagement',
    meta: {
      title: '定额预算管理',
      icon: 'fa fa-database',
      fullPath: '/QuotaDataManagement',
      roles: ['TariffManage', 'constructiondataview', 'constructiondatamanage']
    },
    component: Layout,
    redirect: '/QuotaDataManagement/manpowerLevel',
    children: [
      {
        path: 'manpowerLevel',
        name: 'ManpowerLevel',
        component: () =>
                 import('@/views//QuotaDataManagement/manpowerLevel'),
        meta: {
          title: '人工等级',
          icon: 'fa fa-th-large',
          roles: ['TariffManage'],
          fullPath: '/QuotaDataManagement/manpowerLevel'
        }
      },
      {
        path: 'Rateinformation',
        name: 'RateinformationList',
        component: () =>
                 import('@/views/QuotaDataManagement/Rateinformation'),
        meta: {
          title: '费率信息管理',
          icon: 'fa fa-th-large',
          roles: ['TariffManage'],
          fullPath: '/QuotaDataManagement/Rateinformation'
        }
      },
      {
        path: 'RateinformationInfo/:id',
        name: 'RateinformationInfo',
        component: () =>
                 import('@/views/QuotaDataManagement/RateinformationInfo'),
        meta: {
          title: '费率信息详情',
          icon: 'fa fa-home',
          hidden: true,
          roles: ['TariffManage'],
          fullPath: '/QuotaDataManagement/RateinformationInfo'
        }
      },
      {
        path: 'MaintAndRepairBudgetInfo',
        name: 'MaintAndRepairBudgetInfo',
        component: () =>
                 import('@/views/QuotaDataManagement/MaintAndRepairBudgetInfo'),
        meta: {
          title: '维护维修预算定额',
          icon: 'fa fa-home',
          roles: ['TariffManage'],
          hidden: true,
          fullPath: '/QuotaDataManagement/MaintAndRepairBudgetInfo'
        }
      },
      {
        path: 'HardwareMaintenance',
        name: 'HardwareMaintenance',
        component: () =>
                 import('@/views/QuotaDataManagement/HardwareMaintenance'),
        meta: {
          title: '预算单价管理',
          icon: 'fa fa-home',
          roles: ['TariffManage'],
          hidden: true,
          fullPath: '/QuotaDataManagement/HardwareMaintenance'
        }
      },
      {
        path: 'HardwareMaintenanceInfo/:id',
        name: 'HardwareMaintenanceInfo',
        component: () =>
                 import('@/views/QuotaDataManagement/HardwareMaintenanceInfo'),
        meta: {
          title: '硬件维护单价详情',
          icon: 'fa fa-home',
          hidden: true,
          roles: ['TariffManage'],
          fullPath: '/QuotaDataManagement/HardwareMaintenanceInfo'
        }
      },
      {
        path: 'detail',
        name: 'Detail',
        component: () => import('@/views/QuotaDataManagement/detail'),
        meta: {
          title: '单价详情',
          icon: 'fa fa-home',
          roles: ['TariffManage'],
          hidden: true,
          fullPath: '/QuotaDataManagement/detail'
        }
      },
      {
        path: 'SoftwareMaintenanceInfo/:id',
        name: 'SoftwareMaintenanceInfo',
        component: () =>
                 import('@/views/QuotaDataManagement/SoftwareMaintenanceInfo'),
        meta: {
          title: '软件维护单价详情',
          icon: 'fa fa-home',
          roles: ['TariffManage'],
          hidden: true,
          fullPath: '/QuotaDataManagement/SoftwareMaintenanceInfo'
        }
      },
      {
        path: 'InformationsafetyInfo/:id',
        name: 'InformationsafetyInfo',
        component: () =>
                 import('@/views/QuotaDataManagement/InformationsafetyInfo'),
        meta: {
          title: '信息安全单价详情',
          icon: 'fa fa-home',
          roles: ['TariffManage'],
          hidden: true,
          fullPath: '/QuotaDataManagement/InformationsafetyInfo'
        }
      },
      {
        path: 'HardwareRepairInfo/:id',
        name: 'HardwareRepairInfo',
        component: () =>
                 import('@/views/QuotaDataManagement/HardwareRepairInfo'),
        meta: {
          title: '硬件维修单价详情',
          icon: 'fa fa-home',
          roles: ['TariffManage'],
          hidden: true,
          fullPath: '/QuotaDataManagement/HardwareRepairInfo'
        }
      },
      {
        path: 'SoftwareRepairInfo/:id',
        name: 'SoftwareRepairInfo',
        component: () =>
                 import('@/views/QuotaDataManagement/SoftwareRepairInfo'),
        meta: {
          title: '软件保障单价管理',
          icon: 'fa fa-home',
          roles: ['TariffManage'],
          hidden: true,
          fullPath: '/QuotaDataManagement/SoftwareRepairInfo'
        }
      },
      {
        path: 'EquipmentQuota',
        name: 'EquipmentQuotaList',
        component: () =>
                 import('@/views/QuotaDataManagement/EquipmentQuota'),
        meta: {
          title: '设备定额名称匹配',
          icon: 'fa fa-th-large',
          roles: ['EquipmentQuota'],
          fullPath: '/QuotaDataManagement/EquipmentQuota'
        }
      },
      {
        path: 'ContractList',
        name: 'ContractEndListList',
        component: () =>
                 import('@/views/QuotaDataManagement/ContractList'),
        meta: {
          title: '合同测算预算调整版',
          icon: 'fa fa-th-large',
          roles: ['BudgetManage'],
          hidden: true,
          fullPath: '/QuotaDataManagement/ContractList'
        }
      },
      {
        path: 'ContractInfo',
        name: 'ContractEndListInfo',
        component: () =>
                 import('@/views/QuotaDataManagement/ContractInfo'),
        meta: {
          title: '合同测算预算调整版详情',
          icon: 'fa fa-home',
          roles: ['BudgetManage'],
          hidden: true,
          fullPath: '/QuotaDataManagement/ContractInfo'
        }
      },
      {
        path: 'ContractInfoH',
        name: 'ContractEndListInfo',
        component: () =>
                 import('@/views/QuotaDataManagement/ContractInfoH'),
        meta: {
          title: '合同测算预算调整版详情',
          icon: 'fa fa-home',
          roles: ['BudgetManage'],
          hidden: true,
          fullPath: '/QuotaDataManagement/ContractInfoH'
        }
      },
      {
        path: 'ContractInfoName/:id',
        name: 'ContractEndListInfo',
        component: () =>
                 import('@/views/QuotaDataManagement/ContractInfoName'),
        meta: {
          title: '查看设备参数',
          icon: 'fa fa-home',
          roles: ['BudgetManage'],
          hidden: true,
          fullPath: '/QuotaDataManagement/ContractInfoName'
        }
      },
      {
        path: 'ContractInfodetail/:id',
        name: 'ContractInfodetail',
        component: () =>
                 import('@/views/QuotaDataManagement/ContractInfodetail'),
        meta: {
          title: '合同测算预算详情',
          icon: 'fa fa-home',
          roles: ['BudgetManage'],
          hidden: true,
          fullPath: '/QuotaDataManagement/ContractInfodetail'
        }
      },
      {
        path: 'ContractListReal',
        name: 'ContractListReal',
        component: () =>
                 import('@/views/QuotaDataManagement/ContractListReal'),
        meta: {
          title: '合同测算预算',
          icon: 'fa fa-th-large',
          roles: ['BudgetManage'],
          hidden: true,
          fullPath: '/QuotaDataManagement/ContractListReal'
        }
      },
      {
        path: 'ContractInfoReal',
        name: 'ContractInfoReal',
        component: () =>
                 import('@/views/QuotaDataManagement/ContractInfoReal'),
        meta: {
          title: '合同测算预算详情',
          icon: 'fa fa-home',
          roles: ['BudgetManage'],
          hidden: true,
          fullPath: '/QuotaDataManagement/ContractInfo'
        }
      },
      {
        path: 'directProjectCostManagement',
        name: 'directProjectCostManagement',
        component: () =>
                 import('@/views/QuotaDataManagement/directProjectCostManagement'),
        meta: {
          title: '直接工程费管理',
          icon: 'fa fa-th-large',
          roles: ['TariffManage', 'constructiondataview', 'constructiondatamanage'],
          fullPath: '/QuotaDataManagement/directProjectCostManagement'
        }
      }
    ]
  },
  // // 预算管理相关
  // {
  //   path: '/Budgetmanage',
  //   name: '/Budgetmanage',
  //   meta: {
  //     title: '预算管理',
  //     icon: 'fa fa-database',
  //     fullPath: '/Budgetmanage',
  //     roles: ['BudgetManage']
  //   },
  //   component: Layout,
  //   redirect: '/Budgetmanage/List',
  //   children: [
  //     {
  //       path: 'ContractList',
  //       name: 'ContractEndListList',
  //       component: () =>
  //                import('@/views/Budgetmanage/ContractList'),
  //       meta: {
  //         title: '合同测算预算调整版',
  //         icon: 'fa fa-th-large',
  //         roles: ['BudgetManage'],
  //         fullPath: '/Budgetmanage/ContractList'
  //       }
  //     },
  //     {
  //       path: 'ContractInfo',
  //       name: 'ContractEndListInfo',
  //       component: () =>
  //                import('@/views/Budgetmanage/ContractInfo'),
  //       meta: {
  //         title: '合同测算预算调整版详情',
  //         icon: 'fa fa-home',
  //         roles: ['BudgetManage'],
  //         hidden: true,
  //         fullPath: '/Budgetmanage/ContractInfo'
  //       }
  //     },
  //     {
  //       path: 'ContractInfoH',
  //       name: 'ContractEndListInfo',
  //       component: () =>
  //                import('@/views/Budgetmanage/ContractInfoH'),
  //       meta: {
  //         title: '合同测算预算调整版详情',
  //         icon: 'fa fa-home',
  //         roles: ['BudgetManage'],
  //         hidden: true,
  //         fullPath: '/Budgetmanage/ContractInfoH'
  //       }
  //     },
  //     {
  //       path: 'ContractInfoName/:id',
  //       name: 'ContractEndListInfo',
  //       component: () =>
  //                import('@/views/Budgetmanage/ContractInfoName'),
  //       meta: {
  //         title: '查看设备参数',
  //         icon: 'fa fa-home',
  //         roles: ['BudgetManage'],
  //         hidden: true,
  //         fullPath: '/Budgetmanage/ContractInfoName'
  //       }
  //     },
  //     {
  //       path: 'ContractInfodetail/:id',
  //       name: 'ContractInfodetail',
  //       component: () =>
  //                import('@/views/Budgetmanage/ContractInfodetail'),
  //       meta: {
  //         title: '合同测算预算详情',
  //         icon: 'fa fa-home',
  //         roles: ['BudgetManage'],
  //         hidden: true,
  //         fullPath: '/Budgetmanage/ContractInfodetail'
  //       }
  //     },
  //     {
  //       path: 'ContractListReal',
  //       name: 'ContractListReal',
  //       component: () =>
  //                import('@/views/Budgetmanage/ContractListReal'),
  //       meta: {
  //         title: '合同测算预算',
  //         icon: 'fa fa-th-large',
  //         roles: ['BudgetManage'],
  //         fullPath: '/Budgetmanage/ContractListReal'
  //       }
  //     },
  //     {
  //       path: 'ContractInfoReal',
  //       name: 'ContractInfoReal',
  //       component: () =>
  //                import('@/views/Budgetmanage/ContractInfoReal'),
  //       meta: {
  //         title: '合同测算预算详情',
  //         icon: 'fa fa-home',
  //         roles: ['BudgetManage'],
  //         hidden: true,
  //         fullPath: '/Budgetmanage/ContractInfo'
  //       }
  //     }
  //   ]
  // },
  // 系统变更
  {
    path: '/NetworkSecurity',
    name: 'NetworkSecurity',
    meta: {
      title: '网络安全管理',
      icon: 'fa fa-user',
      fullPath: '/NetworkSecurity'
    },
    component: Layout,
    redirect: '/NetworkSecurity/list',
    children: [
      {
        path: 'systemChangeList',
        name: 'systemChangeList',
        component: () => import('@/views/NetworkSecurity/systemChangeList'),
        meta: {
          title: '系统变更申请列表',
          icon: 'fa fa-th-large',
          roles: ['EnterSystem'],
          fullPath: '/NetworkSecurity/systemChangeList'
        }
      },
      {
        path: 'systemChangeRvwList',
        name: 'systemChangeList',
        component: () => import('@/views/NetworkSecurity/systemChangeRvwList'),
        meta: {
          title: '系统变更审批列表',
          icon: 'fa fa-th-large',
          roles: ['EnterSystem'],
          fullPath: '/NetworkSecurity/systemChangeRvwList'
        }
      },
      {
        path: 'addSystemChange',
        name: 'AddSystemChange',
        meta: {
          title: '系统变更添加',
          icon: 'fa fa-th-large',
          hidden: true,
          roles: ['EnterSystem'],
          fullPath: '/NetworkSecurity/addSystemChange'
        },
        component: () => import('@/views/NetworkSecurity/systemChange')
      },
      {
        path: 'editSystemChange',
        name: 'AddSystemChange',
        meta: {
          title: '系统变更修改',
          icon: 'fa fa-th-large',
          hidden: true,
          roles: ['EnterSystem'],
          fullPath: '/NetworkSecurity/editSystemChange'
        },
        component: () => import('@/views/NetworkSecurity/systemChange')
      },
      {
        path: 'systemChangeDetail',
        name: 'AddSystemChange',
        meta: {
          title: '系统变更详情',
          icon: 'fa fa-th-large',
          hidden: true,
          roles: ['EnterSystem'],
          fullPath: '/NetworkSecurity/systemChangeDetail'
        },
        component: () => import('@/views/NetworkSecurity/systemChange')
      }
    ]
  },
  // 用户相关 权限已调整
  {
    path: '/User',
    name: 'User',
    meta: {
      title: '用户管理',
      icon: 'fa fa-user',
      fullPath: '/User'
    },
    component: Layout,
    redirect: '/User/list',
    children: [
      {
        path: 'list',
        name: 'Userlist',
        component: () => import('@/views/User/list'),
        meta: {
          title: '用户管理',
          icon: 'fa fa-th-large',
          roles: ['CreateUser'],
          fullPath: '/User/list'
        }
      }
    ]
  },
  // 元数据相关 权限已调整
  {
    path: '/Meta',
    name: 'Meta',
    meta: {
      title: '设置',
      icon: 'fa fa-cog',
      fullPath: '/Meta'
    },
    component: Layout,
    redirect: '/Meta/Type',
    children: [
      {
        path: 'brandModel',
        name: 'MetabrandModel',
        meta: {
          title: '品牌型号',
          icon: 'fa fa-th-large',
          roles: ['ManageMeta'],
          fullPath: '/Meta/brandModel'
        },
        component: () => import('@/views/Meta/brandModel')
      },
      {
        path: 'Type',
        name: 'MetaType',
        meta: {
          title: '设备类型',
          icon: 'fa fa-th-large',
          roles: ['ManageMeta'],
          fullPath: '/Meta/Type'
        },
        component: () => import('@/views/Meta/Type')
      },
      {
        path: 'Source',
        name: 'MetaSource',
        meta: {
          title: '设备来源',
          icon: 'fa fa-th-large',
          roles: ['ManageMeta'],
          fullPath: '/Meta/Source'
        },
        component: () => import('@/views/Meta/Source')
      },
      {
        path: 'Equipment',
        name: 'MetaEquipment',
        meta: {
          title: '设备管理',
          icon: 'fa fa-th-large',
          roles: ['ManageMeta'],
          fullPath: '/Meta/Equipment'
        },
        component: () => import('@/views/Meta/Equipment')
      },
      {
        path: 'System',
        name: 'MetaSystem',
        meta: {
          title: '设备系统',
          icon: 'fa fa-th-large',
          roles: ['ManageMeta'],
          fullPath: '/Meta/System'
        },
        component: () => import('@/views/Meta/System')
      },
      {
        path: 'SI',
        name: 'MetaSI',
        meta: {
          title: '设备集成商',
          icon: 'fa fa-th-large',
          roles: ['ManageMeta'],
          fullPath: '/Meta/SI'
        },
        component: () => import('@/views/Meta/SI')
      },
      {
        path: 'positionType',
        name: 'MetapositionType',
        meta: {
          title: '位置类型',
          icon: 'fa fa-th-large',
          roles: ['ManageMeta'],
          fullPath: '/Meta/positionType'
        },
        component: () => import('@/views/Meta/positionType')
      },
      {
        path: 'position',
        name: 'Metaposition',
        meta: {
          title: '位置管理',
          icon: 'fa fa-th-large',
          roles: ['ManageMeta'],
          fullPath: '/Meta/position'
        },
        component: () => import('@/views/Meta/Position')
      },
      {
        path: 'Fault',
        name: 'MetaFault',
        meta: {
          title: '故障管理',
          icon: 'fa fa-th-large',
          roles: ['ManageMeta'],
          fullPath: '/Meta/Fault'
        },
        component: () => import('@/views/Meta/Fault')
      },
      {
        path: 'RepairLevel',
        name: 'MetaRepairLevel',
        meta: {
          title: '维修等级管理',
          icon: 'fa fa-th-large',
          roles: ['ManageMeta'],
          fullPath: '/Meta/RepairLevel'
        },
        component: () => import('@/views/Meta/RepairLevel')
      },
      {
        path: 'Unit',
        name: 'MetaUnit',
        meta: {
          title: '部门管理',
          icon: 'fa fa-th-large',
          roles: ['ManageUnit'],
          fullPath: '/Meta/Unit'
        },
        component: () => import('@/views/Meta/Unit')
      },
      {
        path: 'Role',
        name: 'MetaRole',
        meta: {
          title: '角色管理',
          icon: 'fa fa-th-large',
          roles: ['ManageRole'],
          fullPath: '/Meta/Role'
        },
        component: () => import('@/views/Meta/Role')
      },
      {
        path: 'NormEquipment',
        name: 'MetaNormEquipment',
        meta: {
          title: '定额名称',
          icon: 'fa fa-th-large',
          roles: ['ManageMeta'],
          fullPath: '/Meta/NormEquipment'
        },
        component: () => import('@/views/Meta/NormEquipment')
      },
      {
        path: 'Claims',
        name: 'MetaClaims',
        meta: {
          title: '权限列表',
          icon: 'fa fa-th-large',
          roles: ['ManageRole'],
          fullPath: '/Meta/Claims'
        },
        component: () => import('@/views/Meta/Claims')
      }
    ]
  },
  // 404
  {
    path: '*',
    name: '*',
    meta: {
      title: '*',
      hidden: true,
      fullPath: '/404'
    },
    redirect: '/404',
    hidden: true
  },
  // emergencyRepairRoutes,
  // devopsAssessRoutes,
  integrityRateRoutes,
  repairStatisticsRoutes
  // ,
  // maintenanceSuperviseRoutes
]

const routers = [...constantRoutes, ...asyncRoutes]
export default routers
