var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "has-logo": _vm.showLogo } },
    [
      _vm.showLogo
        ? _c("logo", { attrs: { collapse: _vm.isCollapse } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-scrollbar",
        { attrs: { "wrap-class": "scrollbar-wrapper" } },
        [
          _c(
            "el-menu",
            {
              staticClass: "el-menu-vertical-demo menu",
              attrs: {
                "default-active": _vm.getActiveMenu,
                router: "",
                collapse: _vm.isCollapse,
                "background-color": "#304156",
                "text-color": "#fff",
                "active-text-color": "#409eff",
              },
            },
            [
              _c(
                "el-menu-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.roles.includes("PlatformOverview"),
                      expression: "roles.includes('PlatformOverview')",
                    },
                  ],
                  attrs: { index: "/" },
                },
                [
                  _c("i", { staticClass: "fa fa-map" }),
                  _vm._v(" "),
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _c("a", { attrs: { href: _vm.url, target: "_blank" } }, [
                      _vm._v("运维平台概览展示"),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.routers, function (item, index) {
                return _c(
                  "el-submenu",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          !item.meta.hidden &&
                          _vm.isShowParentMenu(item.children),
                        expression:
                          "!item.meta.hidden && isShowParentMenu(item.children)",
                      },
                    ],
                    key: "title" + index,
                    class: _vm.isCollapse ? "menu_shrink" : "",
                    attrs: { index: item.meta.fullPath },
                  },
                  [
                    _c("template", { slot: "title" }, [
                      _c("i", { class: item.meta.icon }),
                      _vm._v(" "),
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(item.meta.title)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm._l(item.children, function (items, indexs) {
                      return _c(
                        "el-submenu",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !items.meta.hidden &&
                                _vm.isShowMenu2(items) === true,
                              expression:
                                "!items.meta.hidden && isShowMenu2(items)===true",
                            },
                          ],
                          key: "title1" + indexs,
                          attrs: { index: items.meta.fullPath },
                        },
                        [
                          _c("i", { class: items.meta.icon }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(items.meta.title))]
                          ),
                          _vm._v(" "),
                          _vm._l(item.children, function (items2, indexs2) {
                            return _c(
                              "el-menu-item",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      !items2.meta.hidden &&
                                      _vm.isShowMenu(items2) === true,
                                    expression:
                                      "!items2.meta.hidden && isShowMenu(items2)===true",
                                  },
                                ],
                                key: "title2" + indexs2,
                                attrs: { index: items2.meta.fullPath },
                              },
                              [
                                _c("i", { class: items2.meta.icon }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { attrs: { slot: "title" }, slot: "title" },
                                  [_vm._v(_vm._s(items2.meta.title))]
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      )
                    }),
                    _vm._v(" "),
                    _vm._l(item.children, function (items, indexs3) {
                      return _c(
                        "el-menu-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !items.meta.hidden &&
                                _vm.isShowMenu(items) === true,
                              expression:
                                "!items.meta.hidden && isShowMenu(items)===true",
                            },
                          ],
                          key: "title3" + indexs3,
                          attrs: { index: items.meta.fullPath },
                        },
                        [
                          _c("i", { class: items.meta.icon }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(items.meta.title))]
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }