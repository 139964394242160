<template>
  <div class="navbar">
    <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />
    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />
    <div class="right-menu">

      <el-popover placement="bottom" width="280" trigger="click">
        <div>
          <div style="height:175px;background:#2e8cff47;text-align:center;padding-top:40px;">
            <img :src="Logo" style="width:90px;height:90px;border-radius:50%;">
            <div style="text-align:center">
              <a href="javascript:void(0)" @click="changeUpdate()">版本:{{ updataarr[0].edition }}</a>
            </div>  <!-- <h2 style="margin:0">{{ userName }}</h2> -->
          </div>
          <div style="padding:15px 0;display:flex;text-align:center">
            <div style="width:49%;padding:0 10px;">{{ userUnit.name }}</div>
            <div style="width:49%;padding:0 10px;">{{ userName }}</div>
          </div>
          <hr>
          <div>
            <el-button-group>
              <el-button size="small" @click="changePW()">修改密码</el-button>
              <el-button size="small" @click="changeUserInfo()">修改信息</el-button>
            </el-button-group>
            <el-button size="small" style="float:right" @click="logout">注销</el-button>
          </div>
        </div>
        <div slot="reference" style="margin-right:20px;cursor:pointer;">
          <img :src="Logo" style="width:40px;height:40px;border-radius:50%;float:left;margin:5px;">{{ userName }}
        </div>
      </el-popover>
    </div>
    <!-- <el-badge :value="value" dispaly="none" style="float:right; margin-top: 10px;margin-right: 40px;">

      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          <img src="@/assets/dwarning.png" height="15" width="15" style="margin-top: 10px">
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item class="clearfix" @click.native="Detail()">
            未接单 <el-badge class="mark" :value="reportCount" />
          </el-dropdown-item>
          <el-dropdown-item class="clearfix" @click.native="Detail()">
            处理中 <el-badge class="mark" :value="repairCount" />
          </el-dropdown-item>
          <el-dropdown-item class="clearfix" @click.native="Detail()">
            待验收 <el-badge class="mark" :value="reviewCount" />
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-badge> -->
    <el-badge :value="reportCount" dispaly="none" style="float:right; margin-top: 10px;margin-right: 20px;">
      <el-button size="small" @click="Detail(reportCount,1)">未接单</el-button>
    </el-badge>
    <el-badge :value="repairCount" dispaly="none" style="float:right; margin-top: 10px;margin-right: 20px;">
      <el-button size="small" @click="Detail(reportCount,2)">处理中</el-button>
    </el-badge>
    <el-badge :value="reviewCount" dispaly="none" style="float:right; margin-top: 10px;margin-right: 20px;">
      <el-button size="small" @click="Detail(reportCount,3)">待验收</el-button>
    </el-badge>
    <el-badge :value="reportDamageReviewCount" dispaly="none" style="float:right; margin-top: 10px;margin-right: 20px;">
      <el-button size="small" @click="Detail2()">报损待审核</el-button>
    </el-badge>
    <el-dialog title="修改信息" :visible.sync="changeUserFormVisible" :close-on-press-escape="false" :close-on-click-modal="false" width="450px" @close="changeUserFormClose">
      <el-form ref="UserForm" :model="UserForm" :rules="UserFormRules" label-width="120px">
        <el-form-item label="登录账号" prop="userName">
          <el-input v-model="UserForm.userName" disabled placeholder="登录账号" userze="small" />
        </el-form-item>
        <el-form-item label="用户名称" prop="trueName">
          <el-input v-model="UserForm.trueName" placeholder="用户名称" userze="small" />
        </el-form-item>
        <el-form-item prop="contactNumber" label="联系电话">
          <el-input v-model="UserForm.contactNumber" placeholder="联系电话" size="small" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="" @click="changeUserFormVisible=false">关闭</el-button>
        <el-button type="primary" @click="submit()">提交</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改密码" :visible.sync="changePassWordVisibale" :close-on-press-escape="false" :close-on-click-modal="false" width="450px">
      <el-form ref="changePassWord" :model="changePassWord" :rules="changePassWordRules" label-width="120px">
        <el-form-item prop="oldPassword" label="旧密码">
          <el-input v-model="changePassWord.oldPassword" placeholder="旧密码" size="small" />
        </el-form-item>
        <el-form-item prop="newPassword" label="新密码">
          <el-input v-model="changePassWord.newPassword" type="password" placeholder="新密码" size="small" />
        </el-form-item>
        <el-form-item prop="confirmPassword" label="确认密码">
          <el-input v-model="changePassWord.confirmPassword" type="password" placeholder="确认密码" size="small" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="" @click="changePassWordVisibale=false">关闭</el-button>
        <el-button type="primary" @click="submitChangePassWord()">提交</el-button>
      </span>
    </el-dialog>
    <el-dialog title="版本更新内容" :visible.sync="changeUpdateVisibale" :close-on-press-escape="false" :close-on-click-modal="false" class="abow_dialog">
      <div style="white-space:pre-wrap;height:250px;overflow-y:auto;" v-html="updata" />
    </el-dialog>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
// 代码分支87是青海
// import Logo from '@/assets/logo_qh.png'
import Logo from '@/assets/logo_nj.png'
// 京通
// import Logo from '@/assets/logo_jt.png'
// const Warning = require('../../charts/warning.wav')
export default {
  components: {
    Breadcrumb,
    Hamburger
  },
  data() {
    //  代码分支 青海有限制  106---
    const validateNewPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        const reg = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,16}/)
        if (!reg.test(value)) {
          callback(new Error('密码长度为8到16位，且必须同时包含数字、大小写字母及特殊字符'))
        } else {
          callback()
        }
      }
    }
    // const validateNewPass = (rule, value, callback) => {
    //   if (value === '') {
    //     callback(new Error('请输入密码'))
    //   } else {
    //     const regNum = new RegExp(/^\d*$/)
    //     const regStr = new RegExp(/^[a-z\d]*$/i)
    //     if (regNum.test(value) && regStr.test(value)) {
    //       callback(new Error('密码格式不正确，必须同时包含数字和字母'))
    //     } else {
    //       callback()
    //     }
    //   }
    // }
    const validateConfirmPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码密码'))
      } else if (value !== this.changePassWord.newPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      updataarr: [
        { edition: '' }
      ],
      updata: '',
      value: 0,
      reportCount: 0,
      repairCount: 0,
      reviewCount: 0,
      reportDamageReviewCount: 0,
      Logo: Logo,
      userName: this.$cookie.get('trueName'),
      userUnit: JSON.parse(this.$cookie.get('unit')),
      changeUserFormVisible: false,
      UserForm: {
        trueName: this.$cookie.get('trueName'),
        userName: '',
        contactNumber: ''
      },
      UserFormRules: {
        userName: {
          required: true,
          message: '用户名不可为空',
          trigger: 'blur'
        },
        contactNumber: {
          required: true,
          message: '联系方式不可为空',
          trigger: 'blur'
        }
      },
      changePassWordVisibale: false,
      changeUpdateVisibale: false,
      changePassWord: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      changePassWordRules: {
        oldPassword: {
          required: true,
          message: '旧密码不可为空',
          trigger: 'blur'
        },
        newPassword: [
          {
            required: true,
            message: '请输入新密码',
            trigger: 'blur'
          },
          { min: 8, message: '密码长度不可少于8位', trigger: 'blur' },
          { validator: validateNewPass, trigger: 'blur' }
        ],
        confirmPassword: [
          {
            required: true,
            message: '请再次输入密码',
            trigger: 'blur'
          },
          { validator: validateConfirmPassword, trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'avatar',
      'device'
    ])
  },
  mounted() {
    this.updataarr = [
      { 'edition': '2.9.2.0', 'content': '更新时间：2024-03-22 </br>更新内容：</br>1  增加直接工程费管理 </br>2  报损申请界面增加人工费用、措施项目费、直接费、间接费、利润、税金的计算 </br>3  增加新报损管理 ' },
      { 'edition': '2.9.1.0', 'content': '更新时间：2023-09-22 </br>更新内容：</br>1  修改报损管理界面审核、详情页面报损信息模块展示 </br>2  报损管理界面取消导出功能、新增打印功能' }

    ]
    // const href = '</br><el-link type="primary">历史版本' + this.updataarr[1].edition + '</el-link>'

    for (var i = 0; i < this.updataarr.length; i++) {
      this.updata += this.updataarr[i].edition + '</br>' + this.updataarr[i].content + '</br></br>'
    }
    this.getWarning()
    if (this.timer) {
      clearInterval(this.timer)
    } else {
      this.timer = setInterval(() => {
        this.getWarning()
      }, 310000)
    }
    // this.updata = this.updataarr[0].content + '</br></br>' + this.updataarr[1].content + '</br></br>' + this.updataarr[1].content + '</br></br>' + this.updataarr[1].content + '</br></br>' + this.updataarr[1].content + '</br></br>' + this.updataarr[1].content
  },
  methods: {
    Detail(id, state) {
      this.$router.push({
        path: '/Workorder/JobSearch',
        query: { State: state }
      })
      // this.$children[0].$children[0].$forceUpdate()
    },
    Detail2() {
      this.$router.push({
        path: '/ReportDamages/ReportApplyCopy'
      })
    },
    getWarning() {
      this.$axios.get('/api/RepairOrder/Notice').then(res => {
        if (res.reportCount + res.repairCount + res.reviewCount > 0) {
          this.value = res.reportCount + res.repairCount + res.reviewCount
          this.reportCount = res.reportCount
          this.repairCount = res.repairCount
          this.reviewCount = res.reviewCount
          this.reportDamageReviewCount = res.reportDamageReviewCount
          // var audioFile = new Audio(Warning)
          // audioFile.play()
        }
      })
    },
    changeUserInfo() {
      this.changeUserFormVisible = true
      this.UserForm.userName = this.$cookie.get('userName')
      this.UserForm.trueName = this.$cookie.get('trueName')
      this.UserForm.contactNumber = this.$cookie.get('contactNumber')
    },
    changeUserFormClose() {
      this.$refs.UserForm.resetFields()
    },
    submit() {
      this.$refs.UserForm.validate(valid => {
        if (valid) {
          this.$axios.put('/api/Account', this.UserForm).then(res => {
            this.$message.success('修改成功')
            this.$cookie.set('trueName', res.trueName)
            this.$cookie.set('contactNumber', res.contactNumber)
            this.userName = res.trueName
            // this.UserForm.trueName = res.trueName
            // this.UserForm = this.UserForm
            this.changeUserFormVisible = false
            // this.$axios.get('/api/Account/LoginInfo').then(res => {
            //   // cookie 中写入相关用户数据
            //   for (const item in res) {
            //     this.$cookie.set(item, res[item])
            //   }
            //   const roles = []
            //   res.claims.forEach(item => {
            //     roles.push(item.key)
            //   })
            //   roles.push('show')
            //   this.$cookie.set('roles', roles.toString())
            //   this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
            // })
          })
        }
      })
    },
    changePW() {
      this.changePassWordVisibale = true
    },
    changeUpdate() {
      this.changeUpdateVisibale = true
    },
    submitChangePassWord() {
      this.$refs.changePassWord.validate(valid => {
        if (valid) {
          this.$axios.post('/api/Account/UpdatePassword', this.changePassWord).then(res => {
            this.$message.success('密码修改成功,需重新登录。')
            this.logout()
          })
        }
      })
    },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    logout() {
      this.$store.dispatch('tagsView/delAllViews')
      document.cookie.match(/[^ =;]+(?=\=)/g).forEach(item => {
        this.$cookie.remove(item.replace('ioms-', ''))
      })
      this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    width: 195px;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
@media print {
  .navbar{
    display: none;
  }
}

</style>
